(($, window, document, undefined) => {
  'use strict';

  $(() => {
    // FastShell

    //const  bla = 'testing testing';
    //console.log('this is', bla);

  });

})(jQuery, window, document);
